import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const ImageFeature = ({ src, alt, classname = '', reverse = false }) => {
  return <S.Image src={src} alt={alt} className={classname} reverse={reverse} />
}

ImageFeature.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  classname: PropTypes.string,
  reverse: PropTypes.bool,
}

export default ImageFeature
