import styled from 'styled-components'

export const AccordionItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  cursor: pointer;
  padding: 10px;

  h3 {
    color: var(--primary-color);
    font-family: var(--fontBoldItalic);
    font-size: 17px;
    line-height: 17px;
  }
`

export const AccordionContent = styled.div`
  color: var(--primary-color);
  font-family: var(--fontRegularItalic);
  font-style: italic;
  padding: 0 45px;
  font-size: 18px;
  line-height: 20px;
`

export const IconAccordion = styled.img`
  height: auto;
  width: auto;
  margin: initial;
  object-fit: none;
`
