import styled from 'styled-components'

export const FeatureBox = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  gap: 50px;
  padding: 50px 0;

  @media (max-width: 767px) {
    padding: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }

  &.gap100 {
    gap:100px;
  }
`

export const Feature = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }

  img {
    margin-right: 30px;
    width: 150px;

    @media (max-width: 767px) {
      display: block;
      margin: 0 auto 20px auto;
    }
  }

  p {
    color: var(--primary-color);
    font-size: 19px;
    font-style: italic;
    line-height: 22px;
    width: 270px;
  }
`

export const Title = styled.h2`
  color: var(--primary-color);
  font-family: var(--fontRegularItalic);
  font-style: italic;
  font-size: 34px;
  font-weight: 400;
  line-height: 35px;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 30px;
    margin: 20px 0;
    text-align: center;
  }

  strong {
    font-family: var(--fontBoldItalic);
  }

  &.title-nimbus24 {
    text-transform: initial;

    strong {
      text-transform: uppercase;
    }
  }
`

export const Image = styled.img`
  width: ${({ reverse }) => (reverse ? '270px' : '370px')};

  &.thumb-cabedal-nimbus24 {
    width:180px;

    @media (min-width:767px) {
        width: 260px;    
    }
  }

  &.thumb-cabedal-cumulus24 {
    width:100%;

    @media (min-width:1366px) {
      width:720px;
    }
  }

  &.thumb-sola-cumulus24 {
    width:100%;

    @media (min-width:992px) {
      width:342px;
    }
  }

  @media (max-width: 767px) {
    display: block;
    margin: 0 auto;

    &.thumb-cabedal {
      margin-left: 60px;
      width: 190px;
    }

    &.thumb-sola {
      width: 135px;
    }
  }
`
