import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const BoxFeature = ({ className = '', children, reverse = false }) => {
  return <S.FeatureBox className={className} reverse={reverse}>{children}</S.FeatureBox>
}

BoxFeature.propTypes = {
  children: PropTypes.array.isRequired,
  reverse: PropTypes.bool,
}

export default BoxFeature
