import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const TitleFeature = ({ children, classname = '' }) => {
  return <S.Title className={classname}>{children}</S.Title>
}

TitleFeature.propTypes = {
  children: PropTypes.array.isRequired,
  classname: PropTypes.string,
}

export default TitleFeature
