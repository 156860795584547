import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const Feature = ({ children }) => {
  return <S.Feature>{children}</S.Feature>
}

Feature.propTypes = {
  children: PropTypes.array.isRequired,
}

export default Feature
