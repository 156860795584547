import React, { useEffect } from 'react'
import Container from '@material-ui/core/Container'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import Picture from '../../components/Picture'

import VideoModal from '../../components/ModalVideo'
import BoxFeature from '../../components/FeaturesLPs/box'
import Feature from '../../components/FeaturesLPs/feature'
import TitleFeature from '../../components/FeaturesLPs/title'
import ImageFeature from '../../components/FeaturesLPs/image'
import VideoWithModal from '../../components/VideoWithModal'
import FormNewsletter from '../../components/Newsletter'
import Accordion from '../../components/Accordion'

import { ditoTrack } from '../../helpers/dito'

import {
  bannerTopoLg,
  cumulus23,
  cumulus24,
  cumulus24AzulClaro,
  cumulus24AzulLaranja,
  cumulus24Cabedal,
  cumulus24Sola,
  featureCabedal1,
  featureCabedal2,
  featureSola1,
  featureSola2,
  featureSola3,
  featureSola4,
  icoDrop,
  icoEspecificacoesAltura,
  icoEspecificacoesPeso,
  icoPeso,
  runningGuys,
  runningGuysXs,
  thumbVideo,
} from '../../images/gelcumulus24'

import * as S from './styles'
import { graphql } from 'gatsby'

const accordionDataCumulus23 = [
  {
    title: 'JACQUARD MESH',
    content: 'Ajuste no cabedal para mais maciez e respirabilidade.',
  },
  {
    title: 'GEL™ traseiro e visível dianteiro',
    content: 'Mais maciez e absorção de impacto. Mais seguro e confortável.',
  },
  {
    title: 'FLYTEFOAM ™',
    content:
      'Mais leve que espumas comuns, FF oferece ainda mais amortecimento e absorção de choque.',
  },
  {
    title: 'AHAR PLUS ™',
    content:
      'Material estrategicamente inserido no solado para melhorar a durabilidade e ajudar os corredores a obter mais segurança durante sua corrida.',
  },
]

const accordionDataCumulus24 = [
  {
    title: 'JACQUARD MESH',
    content: 'Ajuste no cabedal para mais maciez e respirabilidade.',
  },
  {
    title: 'GEL™ traseiro e visível dianteiro',
    content: 'Mais maciez e absorção de impacto. Mais seguro e confortável.',
  },
  {
    title: 'FF BLAST™',
    content:
      'Espuma de entressola mais leve e macia, oferecendo sensação energizada para o impulso.',
  },
  {
    title: 'AHAR PLUS ™',
    content:
      'Material estrategicamente inserido no solado para melhorar a durabilidade e ajudar os corredores a obter mais segurança durante sua corrida.',
  },
  {
    title: 'ASICS LITE™ RUBBER',
    content:
      'Material de sola mais leve e resistente, projetado para melhorar a resistência contra a ação de abrasões.',
  },
]

const GelCumulus24Page = () => {
  useEffect(() => {
    ditoTrack('acessou-cumulus-24')
  }, [])

  return (
    <Layout>
      <SEO title="GEL Cumulus-24™" />

      <VideoModal videoId="KF-CeQD_RtQ" />

      <Picture
        xs={bannerTopoLg}
        md={bannerTopoLg}
        lg={bannerTopoLg}
        alt="GEL CUMULUS-24™ - Corra e curta a jornada."
      />

      <S.RecursosTecno>
        <Container>
          <h2>Conheça a nova versão do</h2>
          <h3>GEL-CUMULUS ™ 24</h3>

          <S.RecursosTecnoThumb>
            <Picture
              xs={cumulus24AzulLaranja}
              md={cumulus24AzulLaranja}
              lg={cumulus24AzulLaranja}
              alt="GEL CUMULUS-24™ Azul e Laranja"
            />
            <Picture
              xs={cumulus24AzulClaro}
              md={cumulus24AzulClaro}
              lg={cumulus24AzulClaro}
              alt="GEL CUMULUS-24™ Azul Claro"
            />
          </S.RecursosTecnoThumb>
        </Container>
      </S.RecursosTecno>

      {/* TODO: Atualizar thumb quando tiver */}
      <VideoWithModal
        classname="gel-nimbus24"
        title="Um modelo para quem procura sempre"
        subtitle=" se superar"
        thumb={thumbVideo}
      />

      <S.About>
        <Picture
          xs={runningGuysXs}
          md={runningGuysXs}
          lg={runningGuysXs}
          alt="Mais leve, mais confortável para distâncias ainda maiores"
          className="about-image visible-xs"
        />

        <S.AboutText>
          <h1>
            O novo modelo é ideal para <strong>treinos de academia</strong> e
            também para <strong>corredores de diversas distâncias</strong>
          </h1>
        </S.AboutText>

        <Picture
          xs={runningGuys}
          md={runningGuys}
          lg={runningGuys}
          alt="Mais leve, mais confortável para distâncias ainda maiores"
          className="about-image hidden-xs"
        />
      </S.About>

      <S.FeaturesWrapper>
        <BoxFeature reverse>
          <div className="item hidden-xs">
            <ImageFeature
              src={cumulus24Cabedal}
              alt="Sola"
              classname="thumb-cabedal-cumulus24"
              reverse
            />
          </div>
          <div className="item">
            <TitleFeature classname="title-cumulus24">
              A VERSÃO É AINDA{' '}
              <strong>
                MAIS
                <br /> LEVE E CONFORTÁVEL
              </strong>
              ,<br /> DO CABEDAL AO SOLADO
            </TitleFeature>

            <S.FeaturesList>
              <Feature>
                <img src={featureCabedal1} alt="feature Tênis" />
                <p>
                  <strong>Design mais moderno</strong>
                  <br />
                  Do cabedal à entressola, o tênis foi atualizado com design e
                  forma mais joviais para os corredores, apresentando uma nova
                  silhueta.
                </p>
              </Feature>
              <Feature>
                <img src={featureCabedal2} alt="feature Tênis" />
                <p>
                  <strong>JACQUARD MESH</strong>
                  <br />
                  Cabedal macio e respirável com melhor ajuste aos pés.
                </p>
              </Feature>
            </S.FeaturesList>
          </div>
        </BoxFeature>

        <BoxFeature className="gap100">
          <div className="item hidden-xs">
            <ImageFeature
              src={cumulus24Sola}
              alt="Cabedal"
              classname="thumb-sola-cumulus24"
            />
          </div>
          <div className="item">
            <S.FeaturesList>
              <Feature>
                <img src={featureSola1} alt="feature Sola" />
                <p>
                  <strong>FF BLAST™</strong>
                  <br />
                  Espuma de entressola mais leve e macia, oferecendo sensação
                  energizada para o impulso.
                </p>
              </Feature>
              <Feature>
                <img src={featureSola2} alt="feature Sola" />
                <p>
                  <strong>GEL™ traseiro e dianteiro</strong>
                  <br />
                  Elaborado para corredores que procuram um amortecimento de
                  qualidade, oferece uma absorção de impacto mais suave.
                </p>
              </Feature>
              <Feature>
                <img src={featureSola3} alt="feature Sola" />
                <p>
                  <strong>ASICS LITE™ RUBBER</strong>
                  <br />
                  Material de sola mais leve e resistente, projetado para
                  melhorar a resistência contra a ação de abrasões.
                </p>
              </Feature>
              <Feature>
                <img src={featureSola4} alt="feature Sola" />
                <p>
                  <strong>AHAR PLUS ™</strong>
                  <br />
                  Essa é a evolução da tecnologia inicial sendo ainda mais leve
                  e resistente, fornecendo mais flexibilidade e durabilidade.
                </p>
              </Feature>
            </S.FeaturesList>
          </div>
        </BoxFeature>
      </S.FeaturesWrapper>

      <S.ModelosWrapper>
        <S.ModelosTituloWrapper>
          <strong>Descubra o que mudou</strong>
        </S.ModelosTituloWrapper>

        <S.ModelosContentTecnologia>
          <S.ModelosItemTecnologia>
            <img src={cumulus23} alt="gel-cumulus ™ 23" className="thumb" />
            <h2>GEL-CUMULUS ™ 23</h2>
            <S.ModelosAccordionTecnologia>
              <div className="accordion">
                {accordionDataCumulus23.map(({ title, content }) => (
                  <Accordion key={title} title={title} content={content} />
                ))}
              </div>

              <div className="especificacoes">
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesPeso} alt="" />
                  <p>
                    Peso modelo masculino = <span>310g</span>
                  </p>
                </div>
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesAltura} alt="" />
                  <p>
                    Altura entressola = <span>24mm/14mm</span>
                  </p>
                </div>
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesPeso} alt="" />
                  <p>
                    Peso modelo feminino = <span>260g</span>
                  </p>
                </div>
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesAltura} alt="" />
                  <p>
                    Altura entressola = <span>23mm/13mm</span>
                  </p>
                </div>
              </div>
            </S.ModelosAccordionTecnologia>
          </S.ModelosItemTecnologia>

          <S.ModelosItemTecnologia>
            <img src={cumulus24} alt="gel-cumulus ™ 24" className="thumb" />
            <h2>GEL-CUMULUS ™ 24</h2>
            <S.ModelosAccordionTecnologia>
              <div className="accordion">
                {accordionDataCumulus24.map(({ title, content }) => (
                  <Accordion key={title} title={title} content={content} />
                ))}
              </div>

              <div className="especificacoes">
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesPeso} alt="" />
                  <p>
                    Peso modelo masculino = <span>286g</span>
                  </p>
                </div>
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesAltura} alt="" />
                  <p>
                    Altura entressola = <span>24mm/16mm</span>
                  </p>
                </div>
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesPeso} alt="" />
                  <p>
                    Peso modelo feminino = <span>249g</span>
                  </p>
                </div>
                <div className="especificacoes__info">
                  <img src={icoEspecificacoesAltura} alt="" />
                  <p>
                    Altura entressola = <span>23mm/15mm</span>
                  </p>
                </div>
              </div>
            </S.ModelosAccordionTecnologia>
          </S.ModelosItemTecnologia>
        </S.ModelosContentTecnologia>
      </S.ModelosWrapper>

      <S.OndeComprarWrapper>
        <div className="container">
          <h2>Especificações técnicas:</h2>
          <div className="informacoes">
            <div className="informacoes__bloco">
              <img src={icoDrop} alt="" />
              <p>
                Drop = 8mm (24/16mm) (M)
                <br />
                Drop = 8mm (23/15mm) (F)
              </p>
            </div>
            <div className="informacoes__bloco">
              <img src={icoPeso} alt="" />
              <p>
                Peso = 286g (M)
                <br />
                Peso = 249g (F)
              </p>
              <br />
            </div>
            <small>*Peso aproximado baseado no tamanho 40(M) e 38(F)</small>
          </div>
          <span>R$ 799,99</span>

          <S.ButtonComprarWrapper>
            <S.ButtonComprar
              href="http://www.asics.com.br/calcados?filtro_87=GEL-Cumulus-24&utm_source=lab&utm_medium=lp&utm_campaign=crm-cumulus-24"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              comprar agora
            </S.ButtonComprar>
            <S.ButtonComprar
              href="https://api.whatsapp.com/send?phone=551150568450&text=Ola+preciso+de+ajuda"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              comprar pelo whatsapp
            </S.ButtonComprar>
          </S.ButtonComprarWrapper>
        </div>
      </S.OndeComprarWrapper>

      <S.WrapperNewsletter>
        <S.ContentNewsletter>
          <h2>Cadastre seu e-mail para receber ofertas e novidades</h2>

          <S.FormNewsletter>
            <FormNewsletter isLP origem="lp-lab-cumulus24" />
          </S.FormNewsletter>
        </S.ContentNewsletter>
      </S.WrapperNewsletter>
    </Layout>
  )
}

export default GelCumulus24Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
